$fontText: 'Arimo';
$fontTitle: "Libre Caslon Display";
$fontJP: 'PingFang SC';
$max-width: 1640px;
$black: #000;
$white:#fff;
$blue:#eaf6ff;//background-image: linear-gradient(to top, #eaf6ff 0%, #ffffff 100%);
$yellow:#fffce8; //background-image: linear-gradient(to top, #fff8d0 0%, #fffce8 33%, #ffffff 100%);
$purple:#feeaff;//background-image: linear-gradient(to top, #feeaff 0%, #ffffff 100%);

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$mx: 1365px;
