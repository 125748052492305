.story {
  .illus{
    img {
      max-width: 90%;
      @include md-breakpoint() {
        max-width: auto;
        width: auto;
      }
      width: 100%;
      height: auto;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;

    h2 {
      text-align: center;
      margin-top: 65px;
      margin-bottom: 50px;
    }
    h4 {
      font-size: 18px;
      @include md-breakpoint() {
        font-size: 25px;
      }
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      padding: 0 30px;
      line-height: 1.1;
      @include md-breakpoint() {
        font-size: 20px;
        line-height: 1;
      }
      font-weight: 500;
      text-align: center;
    }
    .bt {
      margin-top: 70px;
      margin-bottom: 50px;
      &:hover {
        color: $blue;
      }
    }
  }
}
