@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap');
@font-face {font-family: "PingFang SC";
  src: url("../fonts/PingFangSC.eot"); /* IE9*/
  src: url("../fonts/PingFangSC.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../fonts/PingFangSC.woff2") format("woff2"), /* chrome、firefox */
  url("../fonts/PingFangSC.woff") format("woff"), /* chrome、firefox */
  url("../fonts/PingFangSC.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../fonts/PingFangSC.svg#PingFang SC Regular") format("svg"); /* iOS 4.1- */
}
