body{
  font-family: $fontText;
  font-weight: 500;
  font-size: 16px;
  color: $black;
  background-color: $white;
}
//
// path {
//   stroke-dasharray: 1000;
//   stroke-dashoffset: 1000;
//   animation: dash 10s linear;
// }

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
.hide-sm {
  @media (max-width: $md) {
      display: none !important;
  }
}
.page {
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}
.content {
  margin: 70px auto 0;
  max-width: $max-width;
  @include md-breakpoint() {
    margin: 140px auto 0;
  }
}
.bt {
  padding: 15px 25px;
  border: 2px solid $black;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 700;
  text-transform:uppercase;
  display: inline-flex;
  color: $black;
  text-decoration: none;
  transition: all 400ms;
  &:hover {
    color: $white;
    background-color: $black;
  }
}
.light {
  transition: all 400ms;
  fill:$white;
}
.dark {
  transition: all 400ms;
  fill:$black;
}
h1,h2,h3 {
  font-family: $fontTitle;
  text-transform: uppercase;
  text-align: center;
}
h2 {
  font-size: 80px;
  @include sm-breakpoint() {
    font-size: 100px;
  }
  @include md-breakpoint() {
    font-size: 150px;
  }
}
h4 {
  font-family: $fontText;
  font-size: 18px;
  @include md-breakpoint() {
    font-size: 20px;
  }
  text-transform: uppercase;
  font-weight: 700;
}
p {
  font-family: $fontText;
  font-size: 16px;
}
