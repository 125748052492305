.row {
  margin: 0 auto;
  width: 100%;
  max-width: $max-width;
  display: flex;
  flex-flow: row wrap;
  &__reverse {
    flex-flow: row wrap-reverse !important;
  }
  &.center {
    justify-content: center;
  }
  &.middle {
    align-items: center;
  }
}
.col {
  display: flex;
  flex-flow: column wrap;
  &__centered {
    margin: 0 auto;
  }
}

//12 cols

.col-2 {
  width: 100%;
  @include md-breakpoint() {
    width: 16.66666666%;
  }
}
.col-3 {
  width: 100%;
  @include md-breakpoint() {
    width: 25%;
  }
}
.col-4 {
  width: 100%;
  @include md-breakpoint() {
      width: 33.333333333%;
  }
}
.col-6 {
  width: 100%;
  @include md-breakpoint() {
    width: 50%;
  }
}
.col-8 {
  width: 100%;
  @include md-breakpoint() {
    width: 66.666666666%;
  }
}
.col-10 {
  width: 100%;
  @include md-breakpoint() {
    width: 83.333333333%;
  }
}
