.social {
  border-top:1px solid $black;
  padding-top:85px;
  h2{
    margin-bottom: 40px;
  }
  p {
    font-size: 16px;
    padding: 0 40px;
    @include sm-breakpoint() {
      padding: 0 60px;
      font-size: 20px;
    }
    @include md-breakpoint() {
      font-size: 25px;
    }
    text-align: center;
    font-weight: 700;
  }
  &__links {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 12px auto 50px;
    width: 230px;
    @include md-breakpoint() {
      margin: 36px auto 200px;
      width: 430px;
    }
    li {
      height: 42px;
      display: flex;
      align-items: flex-end;
      padding: 10px;
      svg {
        transition: all 300ms $out-back;
        width: 32px;
        @include md-breakpoint() {
          width: 64px;
        }
      }
      a {

        &:hover {
          svg {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
