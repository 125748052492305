.footer {
  color: $white;

  background-color: $black;
  width: 100%;
  @include md-breakpoint() {
    height: 145px;
    padding-top:55px;
  }
  padding-top:35px;
  p {
    color: $white;
    font-size: 14px;
    font-weight: 400;
  }

  ul {
    padding-top: 18px;
    li {
      a {
        font-size: 14px;
        font-weight: 400;
        color: $white;
        text-decoration: none;
      }
    }
  }
  &__social {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    @include md-breakpoint() {
      padding-bottom: 0;
      display: block;
    }
      li {
        display: inline-flex;
        margin:0 6px;
        a {
          svg {
            width: 31px;
            height: 30px;
            .light{
              fill:$black;
            }
            .dark{
              fill:$white;
            }
          }
          &:hover {
            svg {
              .dark{
                fill:$purple;
              }
              .light{
                fill:$black;
              }

            }
          }
        }
      }
  }
  .col0 {
    display: flex;

    @include md-breakpoint() {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img{

      @include md-breakpoint() {
        margin-right: 40px;
      }
    }
    p {
      @include md-breakpoint() {
        text-align: left;
      }
      text-align: center;
      padding-top: 18px;
      a,a:visited {
        color: $white;
        text-decoration: none;
      }
    }
  }
  .col1{
    width: 50%;
    text-align: center;
    @include md-breakpoint() {
      text-align: left;
      width: 16.666666666%;
    }
  }
  .col2{
    width: 50%;
    text-align: center;
    @include md-breakpoint() {
      text-align: left;
      width: 16.666666666%;
    }
  }
  .col3{
    width: 100%;
    @include md-breakpoint() {
      width: 16.666666666%;
    }
  }
  .col {

  }
}
