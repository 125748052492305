.cartemenu {
/*
  position: fixed;
  background: white;
  top: 72px;
  @include md-breakpoint() {
    top: 144px;
  }
  left: 0;
  width: 100%;
  z-index: 10;*/
  h1 {
    font-family: $fontTitle;
    text-transform: uppercase;
    font-size: 100px;
    font-weight: 400;
    @include md-breakpoint() {
      font-size: 300px;

    }
  }
  ul {
    display: none !important;
    width: 100%;
    display: flex;
    justify-content:space-between;
    flex-flow: wrap;
    align-items:center;
    margin-top: 20px;
    margin-bottom: 10px;

    li {
      padding: 4px 6px;

      transition: all 200ms;
      border: 1px solid #fff;
      a,p {
        font-family: $fontText;
        font-size: 20px;
        color: #000;
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 4px;
      }
      a {
        &:hover {
          border: 1px solid #000;
        }
        &.active {
          border: 1px solid #000;
        }
      }

    }
  }


}
.carte {
  margin: 0 10px;
  /*margin-top: 320px;
  @include md-breakpoint() {
    margin-top: 520px;
  }*/
  width: calc( 100% - 20px );
  h4 {
    padding-top: 40px;
    font-weight: 700;
    font-family: $fontText;
    text-decoration: underline;
    width: 100%;
    text-align: left;
    padding-bottom: 5px;
  }
  &__item {
    width: 100%;

    h2 {
      font-size: 50px;
      font-weight: 700;
      font-family: $fontText;

    }

    &_extra {

      padding-bottom: 40px;
      display: block;
      @include md-breakpoint() {
        display: inline-flex;
      }
      ul {
        padding: 0;

        @include md-breakpoint() {
          font-size: 50px;
          padding-right: 200px;
        }
        li {
          font-family: $fontText;
          font-size: 20px;
          font-weight: 500;
          &.jp {
            font-size: 18px;
          }
          &.price {
            font-family: $fontText;
            font-size: 22px;
            font-weight: 700;
          }
        }
      }
    }
    &_title {
      padding-bottom: 40px;
      padding-top: 50px;
      border-bottom: 2px solid black;
      h2 {
        font-size: 30px;
        font-weight: 700;
        font-family: $fontText;
        text-align: left;
        @include md-breakpoint() {
          font-size: 50px;

        }
      }
      p {
        font-size: 20px;
        text-align: left;
        font-weight: 500;
        font-family: $fontText;
      }
    }
    &_plate {
      width: 100%;
      border-bottom: 2px solid black;

      display: inline-flex;
      flex-direction: row;
      padding-top: 15px;
      padding-bottom: 25px;
      &--name {
        p {
          font-size: 16px;
          font-weight: 500;
          font-family: $fontText;
          text-align: left;
          @include md-breakpoint() {
            font-size: 20px;

          }
        }
        h3 {
          font-size: 20px;
          font-weight: 700;
          font-family: $fontText;
          text-align: left;
          @include md-breakpoint() {
            font-size: 25px;

          }
        }
        width: calc( 100% - 100px );
        @include md-breakpoint() {
          width: calc( 100% - 200px );

        }
      }
      &--price {
        width: 100px;
          @include md-breakpoint() {
          width: 200px;
        }
        p {
          font-size: 20px;
          font-weight: 500;
          font-family: $fontText;
          text-align: right;
          @include md-breakpoint() {
            font-size: 25px;

          }
        }
      }
    }
  }
}
.jp {
  font-family: $fontJP;
  font-weight: 600;
  text-transform: uppercase;
}
