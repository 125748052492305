.bkg {
  position: fixed;
  z-index: 1;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 480px;
  &__gradient{
    position: absolute;
    left: 0;top:0;
    width: 100%;
    height: 100%;
    background-repeat: repeat-x;
    opacity: 0;
    transition: opacity 500ms;
    &.active {
      opacity: 1;
    }
  }
  &__header {
    background-image: url('../img/yellow.png');
  }
  &__menu {
    background-image: url('../img/yellow.png');
  }
  &__social {
    background-image: url('../img/purple.png');
  }
  &__story {
    background-image: url('../img/blue.png');
  }
}
