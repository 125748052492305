.header-mobile {
  position: fixed;
  z-index: 200;
  top:0;
  left: calc( 100vw + 10px );
  transition: all 400ms $out-circ;
  &.open {
    left: 0px;
  }
  background-color: $black;
  height: 100vh;
  width: 100vw;
  &__close {
    position: absolute;
    z-index: 2;
    right: 30px;
    top: 30px;
  }
  &__menu {
    padding: 60px 40px;

      li {
        a {
          font-size: 40px;
          font-weight: 400;
          color: $white;
          position: relative;
          text-decoration: none;
        }
      }

  }
  &__social {
    position: absolute;
    bottom: 10px;
    left: 30px;
    justify-content: flex-start;
  }
}
.header{
  position: fixed;
  z-index: 100;
  max-width: calc( $max-width - 100px );
  width: calc( 100% - 40px );
  padding: 20px;
  @include md-breakpoint() {
    width: calc( 100% - 100px );
    padding: 0 50px;
    height: 104px;
  }


  left: 0px;
  right: 0px;
  margin: auto;
  top: 0px;
  @include md-breakpoint() {
    top: 40px;
  }
  background-color: $white;
  display: flex;
  flex-direction: row;
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    ul {
      li {
        display: inline-flex;
        margin-right: 90px;
        &:nth-last-child(){
          margin-right:0px;
        }
        a {
          font-size: 18px;
          font-weight: 700;
          color: $black;
          position: relative;
          text-decoration: none;

          &:after {
            @include pseudo-class;
            bottom:-6px;
            left: 0;
            width: 0%;
            height: 2px;
            background-color: $black;
            transition: width 600ms $out-circ;
          }
          &:hover {
            &:after {
              width: 100%;
            }
          }
          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__center {
      width: 40%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      img {
        width: 68px;
      }
      @include md-breakpoint() {
        img {
          width: auto;
        }
        display: block;
        @include absolute(10);
        @include dimensions(130px, 44px);
        left: 0;top:-16px;
        right: 0;bottom:0;
        margin:auto;
      }
  }
  &__right {
    width: 60%;
      @include md-breakpoint() {
      width: 50%;
    }
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &_social {
        margin: 0 4px;
        a {
          svg{
            transition: opacity 400ms;
          }
          &:hover {
            svg {
              opacity: 0.7;
            }
          }
        }
      }
    ul {
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        display: inline-flex;
        line-height: 1;
        &.burger {
          padding-left: 20px;
          display: flex;
          @include md-breakpoint() {
            display: none;
          }
        }
        a {
          svg {
            width: 31px;
            height: 30px;
          }
          &.contact {
            font-size: 14px;
            font-weight: 600;
            color: $black;
            position: relative;
            text-decoration: none;
            margin-right: 25px;
            &:after {
              @include pseudo-class;
              bottom:-2px;
              left: 0;
              width: 0%;
              height: 2px;
              transition: width 600ms $out-circ;
              background-color: $black;
            }
            &:hover {
              &:after {
                width: 100%;
              }
            }
            &.active {
              &:after {
                width: 100%;
              }
            }
          }
          &.commande {
            margin-right: 20px;
            font-size: 10px;
            @include md-breakpoint() {
              font-size: 18px;
              margin-left: 70px;
              margin-right: 50px;
            }
            font-weight: 700;

            position: relative;
            text-decoration: none;
            z-index: 10;
            span{
              transition: all 600ms $out-circ;
              color: $black;
              position: relative;
              z-index: 3;
            }
            &:after {
              @include pseudo-class(1);


              border-radius: 44px / 18px;
              left: -17px;
              bottom:-14px;
              width: 87px;
              height: 32px;
              border: 2px solid $black;
              @include md-breakpoint() {
                bottom:-27px;

                border-radius: 98px / 36px;
                left: -50px;
                width: 194px;
                height: 71px;
                border: 3px solid $black;
              }

              background-color: $white;
              //background-image:linear-gradient(to top, $black 0%, $black 100%);
              transition: all 600ms $out-circ;
            }
            // &:before {
            //   @include pseudo-class(2);
            //   bottom:-27px;
            //   border-radius: 98px / 36px;
            //   left: -50px;
            //   width: 186px;
            //   height: 64px;
            //
            //   background-color: $white;
            //   transition: all 600ms $out-circ;
            // }
            &:hover {
              span{
                color: $white;
              }

              &:after {
                background-color: $black;
                //background-image:linear-gradient(to top, #feeaff 0%, #fff8d0 100%);
                // border-image:
                //background-image: linear-gradient(to top, #feeaff 0%, #ffffff 100%);
                //background-image: linear-gradient(to top, #fff8d0 0%, #fffce8 33%, #ffffff 100%);
              }
            }
          }
        }
      }
    }
  }
}
