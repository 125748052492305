.splash {
  padding-top: 35px;
  height: 100vh;
  @include md-breakpoint() {
    height: calc( 100vh - 140px );
  }
  &__title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &_logo {
      transform-origin: center;
      animation-duration: 20s;
    }

  }
  &__cols {
    margin-top: 0px;
    @include md-breakpoint() {
      margin-top: 80px;
    }
  }
  .col {
    align-items: center;
    justify-content: center;
    svg {
      @include md-breakpoint() {
        max-width: 100%;
      }
      max-width: 90%;
      width: 100%;
      height: auto;
    }


  }
  h3 {
    @include md-breakpoint() {
      font-size: 33px;
      padding: 0 10px;
    }
    font-size: 20px;
    padding: 40px 80px;
    @include sm-breakpoint() {
      font-size: 26px;
      padding: 40px 100px;
    }
  }
}
