.menu {
  margin-bottom: 85px;
  h2 {
    margin-bottom: 40px;
  }
  p {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding: 0 40px;
    @include md-breakpoint() {
      padding: 0;
      font-size: 25px;
    }
  }
  .row {
    justify-content: center;
    align-items: center;
  }
  .col-4 {
    justify-content: center;
    align-items: center;
  }
  &__cols {
    padding: 45px 0;
    margin-top: 45px;
    margin-bottom: 45px;
    border-top:1px solid $black;
    border-bottom:1px solid $black;
  }
  &__col {
    padding-bottom:30px;
    @include md-breakpoint() {
      padding: 0;
    }
    h4 {
      font-weight: 700;
      font-size: 20px;
      margin-bottom:10px;
      @include md-breakpoint() {
        margin-bottom:6px;
      }
    }
    p {
      font-weight: 500;
      font-size: 20px;
      padding: 0 60px;
      font-weight: 400;
        @include md-breakpoint() {
          font-weight: 500;
        }
    }
  }
  &__bt {
    font-size: 18px;
    font-weight: 700;
    &:hover {
      color: $yellow;
    }
  }
}
